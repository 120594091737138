<template>
  <div class="container">
    <van-grid square :column-num="3">
      <van-grid-item v-for="(item, index) in list" :key="index">
        <div
          v-if="
            1 === index ||
            3 === index ||
            4 === index ||
            5 === index ||
            7 === index
          "
          class="flex-direction"
          :class="`flex-direction-${index}`"
        >
          <div class="icon-title">{{ item.title }}</div>
          <van-image
            v-if="item.icon"
            :src="require('@/assets/images/position.png')"
            :style="{ transform: `rotate(${item.spin}deg)` }"
          />
          <div v-else></div>
        </div>
      </van-grid-item>
    </van-grid>

    <ul
      :style="{
        fontSize: '0.32rem',
        fontWeight: 400,
        color: '#515151',
        marginTop: '0.74rem',
      }"
    >
      <li>{{ $t("drawNineSquaresTips1") }}</li>
      <li>{{ $t("drawNineSquaresTips2") }}</li>
      <li>{{ $t("drawNineSquaresTips3") }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title: "",
          icon: false,
          spin: false,
        },
        {
          title: this.$t("south"),
          icon: "require('@/assets/image.png')",
          spin: 0,
        },
        {
          title: "",
          icon: false,
          spin: false,
        },
        {
          title: this.$t("east"),
          icon: "@/images/position.png",
          spin: -90,
        },
        {
          title: this.$t("nakamiya"),
          icon: false,
          spin: false,
        },
        {
          title: this.$t("weat"),
          icon: "@/images/position.png",
          spin: 90,
        },
        {
          title: "",
          icon: false,
          spin: false,
        },
        {
          title: this.$t("north"),
          icon: "@/images/position.png",
          spin: 180,
        },
        {
          title: "",
          icon: false,
          spin: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  background: #fbf7f3;
  padding: 0.7rem 0.48rem 0;
  font-size: 0.36rem;
  font-weight: 600;

  .flex-direction {
    display: flex;
    align-items: center;
  }

  .flex-direction-1 {
    flex-direction: column-reverse;
    margin-top: -20%;
    .icon-title {
      margin-top: 0.14rem;
    }
  }
  .flex-direction-3 {
    flex-direction: row-reverse;
    margin-left: -20%;
    .icon-title {
      margin-left: 0.14rem;
    }
  }
  .flex-direction-5 {
    margin-right: -20%;
    .icon-title {
      margin-right: 0.14rem;
    }
  }
  .flex-direction-7 {
    flex-direction: column;
    margin-bottom: -20%;
    .icon-title {
      margin-bottom: 0.14rem;
    }
  }
}
/deep/ {
  .van-hairline--top {
    border-top: 0.06rem solid #e6dcc9;
  }
  .van-hairline--top {
    border-left: 0.06rem solid #e6dcc9;
  }
  .van-hairline--top::after {
    border-top-width: 0;
  }
  [class*="van-hairline"]::after {
    border: 0 solid #e6dcc9;
  }
  .van-grid-item__content::after {
    border-width: 0 0.12rem 0.12rem 0;
  }
  .van-image__img {
    width: 0.4rem;
    height: 0.4rem;
  }
}
</style>
