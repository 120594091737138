var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('van-grid',{attrs:{"square":"","column-num":3}},_vm._l((_vm.list),function(item,index){return _c('van-grid-item',{key:index},[(
          1 === index ||
          3 === index ||
          4 === index ||
          5 === index ||
          7 === index
        )?_c('div',{staticClass:"flex-direction",class:`flex-direction-${index}`},[_c('div',{staticClass:"icon-title"},[_vm._v(_vm._s(item.title))]),(item.icon)?_c('van-image',{style:({ transform: `rotate(${item.spin}deg)` }),attrs:{"src":require('@/assets/images/position.png')}}):_c('div')],1):_vm._e()])}),1),_c('ul',{style:({
      fontSize: '0.32rem',
      fontWeight: 400,
      color: '#515151',
      marginTop: '0.74rem',
    })},[_c('li',[_vm._v(_vm._s(_vm.$t("drawNineSquaresTips1")))]),_c('li',[_vm._v(_vm._s(_vm.$t("drawNineSquaresTips2")))]),_c('li',[_vm._v(_vm._s(_vm.$t("drawNineSquaresTips3")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }